$(document).ready(function () {
    /**
     * 导航动效
     */
    var animatedHeader = (function () {
        var docElem = document.documentElement,
            header = $('#header'),
            didScroll = false;

        scrollPage();
        window.addEventListener('scroll', function (event) {
            if (!didScroll) {
                didScroll = true;
                setTimeout(scrollPage, 250);
            }
        }, false);

        function scrollPage() {
            var scrollY = window.pageYOffset || docElem.scrollTop;
            if (scrollY >= 150) {
                header.removeClass('expand')
            } else {
                header.addClass('expand')
            }
            didScroll = false;
        }
    })();

    /**
     * 多级下拉菜单
     */
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }

        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass("show");
        });

        return false;
    });

    /**
     * 百度地图
     */
    var mapBox = $('#map-box');
    if (mapBox.length > 0) {
        var mapInfo = $.extend({
            lng: 87.586399, // 经度
            lat: 43.875055, // 纬度
            title: '新疆百疆图网络服务有限公司', // 公司名称
            message: '地址：乌市新市区长沙路158号绿苑雅筑2号楼4楼' // 地址
        }, window.MAP_INFO);

        // 百度地图API功能
        var map = new BMap.Map("map-box");
        var point = new BMap.Point(mapInfo.lng, mapInfo.lat);
        var marker = new BMap.Marker(point); // 创建标注
        map.addOverlay(marker); // 将标注添加到地图中
        map.centerAndZoom(point, 15);

        var opts = {
            width: 200, // 信息窗口宽度
            height: 100, // 信息窗口高度
            title: mapInfo.title, // 信息窗口标题
            enableMessage: true, //设置允许信息窗发送短息
            message: mapInfo.message
        }
        var infoWindow = new BMap.InfoWindow(mapInfo.message, opts); // 创建信息窗口对象 
        // map.openInfoWindow(infoWindow, point); //开启信息窗口
        marker.addEventListener("click", function () {
            map.openInfoWindow(infoWindow, point); //开启信息窗口
        });

        var top_left_navigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件

        map.addControl(top_left_navigation);
    }

    /**
     * 返回顶部
     */
    var page = $(document);
    var scrollTotop = $('.scroll-to-top');
    if (scrollTotop.length > 0) {
        if (page.scrollTop() > 300) {
            scrollTotop.fadeIn();
        }
        page.on('scroll', function () {
            if (page.scrollTop() > 300) {
                scrollTotop.fadeIn();
            } else {
                scrollTotop.fadeOut();
            }
        });
        scrollTotop.click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    }
 
     //产品列表页导航折叠
     $("nav").on("click", function () {
        if($("#fold-content").hasClass("openFold")) {
          $("#fold-content").removeClass("openFold");
          $(".fold > i").css("transform", "rotate(0deg)");
          $(".fold").attr("title", "点击展开");
        } else {
          $("#fold-content").addClass("openFold");
          $(".fold > i").css("transform", "rotate(180deg)");
          $(".fold").attr("title", "点击收起");
        }
      });  



});